import axios from "axios";

var cf_ = null;
if (process.env.NODE_ENV == "development" || process.env.NODE_ENV == "development_online") {
  console.log("export cf as dev ", process.env.NODE_ENV);
  cf_ = axios.create({
    //dev
    baseURL: "https://us-central1-lokal-it-dev.cloudfunctions.net/api/v1",
    headers: {
      "Content-type": "application/json"
    }
  });
}else if (process.env.NODE_ENV == "production"){
  console.log("export cf as prod ", process.env.NODE_ENV);
  cf_ = axios.create({
    //prod
    baseURL: "https://us-central1-lokali-product.cloudfunctions.net/api/v1",
    headers: {
      "Content-type": "application/json"
    }
  });
}

export const cf = cf_;

export const cr = axios.create({
  baseURL: "https://util-textsearch-vu6gor4v6q-uc.a.run.app",
  headers: {
    "Content-type": "application/json"
  }
});

var search_ = null;
if (process.env.NODE_ENV == "development" || process.env.NODE_ENV == "development_online") {
  search_ = axios.create({
    //dev
    baseURL: "https://us-central1-lokal-it-dev.cloudfunctions.net/search/v1",
    headers: {
      "Content-type": "application/json"
    }
  });
}else if (process.env.NODE_ENV == "production"){
  search_ = axios.create({
    //prod
    baseURL: "https://us-central1-lokali-product.cloudfunctions.net/search/v1",
    headers: {
      "Content-type": "application/json"
    }
  });
}
export const search = search_;

//youtube
export const youtube = axios.create({
  baseURL: "https://video.google.com",
  headers: {
    "Content-type": "application/json"
  }
});

var nlp_algolia_cf_ = axios.create({
  baseURL: "https://us-central1-lokanlp.cloudfunctions.net/algolia/v1",
  headers: {
    "Content-type": "application/json"
  }
});
export const nlp_algolia_cf = nlp_algolia_cf_;