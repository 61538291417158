import Vue from 'vue'
import VueRouter from 'vue-router'
//import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  /*{
    path: '/',
    name: 'Home',
    component: Home
  },*/
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue'),
    meta: { keepAlive: true }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/testmobile',
    name: 'TestMobile',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/TestMobile.vue')
  },
  //{
  //  path: '/shops',
  //  name: 'Shops',
  //  component: () => import(/* webpackChunkName: "about" */ '../views/ShopList.vue')
  //},
  {
    path: '/shop/:id',
    name: 'Shop',
    component: () => import(/* webpackChunkName: "about" */ '../views/Shop.vue'),
    alias: '/org/:id',
    metaInfo:{
      meta: [
        {
          name: 'viewport',
          content: 'width=device-width,initial-scale=1.0,minimum-scale=1.0'
        }]
    }
  },
  //{
  //  path: '/chats',
  //  name: 'Chats',
  //  component: () => import(/* webpackChunkName: "about" */ '../components/CustomerChat.vue')
  //},
  //{
  //  path: '/chat/:roomId',
  //  name: 'Chat',
  //  component: () => import(/* webpackChunkName: "about" */ '../components/CustomerChat.vue')
  //},
  //{
  //  path: '/adminchat',
  //  name: 'AdminChat',
  //  component: () => import(/* webpackChunkName: "about" */ '../components/AdminChat.vue')
  //},
  {
    path: '/business/edittool',
    name: 'ContentTool',
    component: () => import(/* webpackChunkName: "about" */ '../views/ContentTool.vue')
  },
  {
    path: '/business/:shopId/newslist',
    name: 'NewsList',
    component: () => import(/* webpackChunkName: "about" */ '../views/business/NewsList.vue')
  },
  {
    path: '/business/:shopId/editContent',
    name: 'ContentEditor',
    component: () => import(/* webpackChunkName: "about" */ '../views/business/ContentEditor.vue')
  },
  {
    path: '/shop/:shopId/qkvw/:itemType/:itemId',
    name: 'QuickView',
    component: () => import(/* webpackChunkName: "about" */ '../views/QuickView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
