import {cf, search, youtube, nlp_algolia_cf} from "../http-common.js";

class DataService {
  login(username, password){
    return cf.get(`/login?username=${username}&password=${password}`);
  }
  register(username, password, confirm_password, display_name, phone, prefecture, town){
    return cf.post(`/register`,{userName:username, displayName: display_name, password:password, confirmPassword: confirm_password, phone:phone,prefecture:prefecture,town:town});
  }
  checkCustomer(userId){
    return cf.get(`/user_exist/${userId}`);
  }
  getShopList(id) {
    return cf.get(`/shoplist?shopId=${id}`);
  }
  getShop(id) {
    return cf.get(`/shop/${id}`);
  }
  getProduct(shopId, productId) {
    return cf.get(`/shop/${shopId}/product/${productId}`);
  }
  searchkeyword(keyword){
    return cf.get(`/searchkeyword?keyword=${keyword}`);
  }
  findChatRoom(userId, shopId, productId=null){
    if(productId)
      return cf.get(`/findChatRoom?userId=${userId}&shopId=${shopId}&productId=${productId}`);
    else
      return cf.get(`/findChatRoom?userId=${userId}&shopId=${shopId}`);
  }
  createChatRoom(userId, shopId, productId, shopStaffs, roomName){
    return cf.post(`/createChatRoom`,{userId: userId, shopId: shopId, productId: productId, shopStaffs: shopStaffs, roomName: roomName});
  }
  getSuggestions(shopId, sourceId){
    return cf.get(`/shop/${shopId}/suggestions?sourceId=${sourceId}`);
  }
  getNewsList(shopId, limit=-1, dateRange=-1){
    var queryString = `/shop/${shopId}/getnews`;
    if (limit > 0){
      if (dateRange > 0){
        queryString += `?limit=${limit}&daybefore=${dateRange}`;
      }else{
        queryString += `?limit=${limit}`;
      }
    }else{
      if (dateRange > 0){
        queryString += `?daybefore=${dateRange}`;
      }
    }
    return cf.get(queryString);
  }
  getNews(shopId, newsId) {
    return cf.get(`/shop/${shopId}/readnews/${newsId}`);
  }
  updateNews(shopId, newsId, news){
    return cf.post(`/shop/${shopId}/news/${newsId}`, news);
  }
  addNews(shopId, news){
    return cf.post(`/shop/${shopId}/addnews`, news);
  }
  getHistoryList(shopId){
    return cf.get(`/shop/${shopId}/history`);
  }
  addHistory(shopId, history){
    return cf.post(`/shop/${shopId}/addhistory`, history);
  }
  updateHistory(shopId, historyId, history){
    return cf.post(`/shop/${shopId}/history/${historyId}/update`, history);
  }
  getProductList(shopId){
    return cf.get(`/shop/${shopId}/getProducts`);
  }
  getSimilarProducts(shopId, type, type2, limit){
    return cf.get(`/shop/${shopId}/getSimilarProducts?limit=${limit}&prod_type=${type}&prod_type2=${type2}`);
  }
  getFqaList(shopId){
    return cf.get(`/shop/${shopId}/getFqas`);
  }
  getTrackingSystem(shopId, type){
    return cf.get(`/shop/${shopId}/trackingSystem/query?type=${type}`);
  }
  getChatPlugin(shopId, type){
    return cf.get(`/shop/${shopId}/chatplugin/query?type=${type}`);
  }
  getNearOrg(lat, lng, radiusInM = 10000){
    if (!lat || !lng){
      return null;
    }
    return cf.get(`/find/getNearOrg?lat=${lat}&lng=${lng}&radius=${radiusInM}`);
  }

  //Search api
  searchByKeyword(keyword, lat, lng, radiusInM){
    return search.get(`/generalSearch?keyword=${keyword}&lat=${lat}&lng=${lng}&radius=${radiusInM}`);
  }
  searchNews(lat, lng, radiusInM, daybefore=7, page=0){
    return search.get(`/getNews?keyword=&lat=${lat}&lng=${lng}&radius=${radiusInM}&daybefore=${daybefore}&page=${page}`);
  }
  searchNearOrg(lat, lng, radiusInM = 10000, page=0){
    return search.get(`/getNearOrg?keyword=&lat=${lat}&lng=${lng}&radius=${radiusInM}&page=${page}`);
  }
  

  //Youtube api
  getTranscript(ytId, language){
    return youtube.get(`/timedtext?lang=${language}&v=${ytId}`);
    ////http://video.google.com/timedtext?lang=en&v=Az0W61hotLM
  }
  getVideo(videoId){
    return cf.get(`/video/${videoId}/get`);
  }
  updateVideo(videoId, video){
    return cf.post(`/video/${videoId}/update`, video);
  }
  addVideo(video){
    return cf.post(`/video/add`, video);
  }

  //nlp api
  searchQdefContent(keyword){
    return nlp_algolia_cf.get(`/generalSearch?keyword=${keyword}`);
  }
}

export default new DataService();
