<template>
  <v-app >
    <!--<v-toolbar dense flat class="grey lighten-5">-->
    <v-app-bar dense flat app absolute color="grey lighten-5" v-if="lokali_bar">
      <span class="ml-4 lokali_title"><span class="blue-grey--text darken-2">lo</span><span class="orange--text">k</span><span
          class="blue-grey--text darken-2">ali</span>
      </span>
      <v-spacer></v-spacer>
      <div v-if="!debug">
      <div v-if="!lg_account">
        <v-dialog v-model="dialog" persistent max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon color="primary" dark v-bind="attrs" v-on="on">
              <v-icon>mdi-login</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Đăng nhập</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="lg_username" label="Tài khoản*" required></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="lg_password" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="show1 ? 'text' : 'password'" label="Mật khẩu*" @click:append="show1 = !show1">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" v-if="login_error">
                    <span color="red">{{login_error}}</span>
                  </v-col>
                </v-row>
              </v-container>
              <span>Bạn chưa có tài khoản?</span>
              <v-btn color="orange darken-1" text @click="dialog = false; dialog2 = !dialog2">Tạo tài khoản ngay
              </v-btn>

            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="lg_password='';dialog = false">
                Đóng
              </v-btn>
              <v-btn color="blue darken-1" text @click="login(lg_username, lg_password)">
                Đăng nhập
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialog2" max-width="500px">
          <v-card>
            <v-card-title>
              <span>Đăng ký</span>
              <v-spacer></v-spacer>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field v-model="re_username" label="Tên tài khoản*" required :rules="[rules.required]"
                      hint="example of helper text only on focus"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :type="show1 ? 'text' : 'password'"
                      :rules="[rules.required, rules.min]" v-model="re_password" label="Mật khẩu*" required
                      hint="example of helper text only on focus"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="64">
                    <v-text-field :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :type="show1 ? 'text' : 'password'"
                      :rules="[rules.required, rules.min]" v-model="re_confirmPwd" label="Xác nhận mật khẩu*" required
                      hint="example of helper text only on focus"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field v-model="re_displayname" label="Tên hiển thị*" required :rules="[rules.required]"
                      hint="example of helper text only on focus"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field v-model="re_phone" label="Số điện thoại" required
                      hint="example of helper text only on focus"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-autocomplete ref="re_prefecture" v-model="re_prefecture"
                      :rules="[() => !!re_prefecture || 'This field is required', prefectureCheck() || 'Chưa hỗ trợ']"
                      :items="prefectures" label="Tỉnh/ Thành phố " placeholder="Chọn tỉnh/ thành phố" required>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-autocomplete ref="re_town" v-model="re_town"
                      :rules="[() => !!re_town || 'This field is required']" :items="towns" label="Quận/ Huyện"
                      placeholder="Chọn quận/ huyện" required></v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog2 = false">
                Đóng
              </v-btn>
              <v-btn color="blue darken-1" text @click="register()">
                Đăng ký
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="require_acc_dialog" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">Thông báo</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <h3>Bạn cần đăng nhập để thực hiện chức năng này.</h3>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <span>Bạn đã có tài khoản?</span>
                    <v-btn color="orange darken-1" text @click="require_acc_dialog=false; dialog=true;">Đăng nhập ngay
                      !</v-btn>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <span>Bạn chưa có tài khoản?</span>
                    <v-btn color="orange darken-1" text @click="require_acc_dialog=false; dialog2=true;">Đăng ký ngay
                      !</v-btn>
                  </v-col>
                </v-row>
              </v-container>

            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="require_acc_dialog = false">
                Đóng
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
      <div v-else>
        {{lg_account.displayName}}
      </div>
      <v-btn icon to="/">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
      <v-btn icon to="/chats" v-if="lg_account">
        <v-icon>mdi-message-text</v-icon>
      </v-btn>
      <v-menu bottom offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <div v-if="lg_account">
          <v-list>
            <v-list-item @click="logout()">
              <v-list-item-title>Đăng xuất</v-list-item-title>
            </v-list-item>
          </v-list>
        </div>
        <div v-else>
          <v-list>
            <v-list-item @click="dialog = true">
              <v-list-item-title>Đăng nhập</v-list-item-title>
            </v-list-item>
          </v-list>
        </div>
      </v-menu>
    </div>
      <div class="text-center">
        <v-snackbar v-model="snackbar" :timeout="timeout">
          {{ snackbar_text }}

          <template v-slot:action="{ attrs }">
            <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </div>
    </v-app-bar>
    <v-container class="py-0 px-0 mb-12 mt-0">
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive" :common="common" />
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive" :common="common" />
    </v-container>
    
    <v-footer padless app absolute>
      <v-col class="text-center" cols="12">
        {{ new Date().getFullYear() }} — <strong><span class="blue-grey--text darken-2">lo</span><span class="orange--text">k</span><span
          class="blue-grey--text darken-2">ali</span></strong>
      </v-col>
    </v-footer>
  </v-app>
</template>
<script>
  //import firebaseAuth from './firestore/index.js'
  import {dat_prefectures, dat_towns} from './utils/prefectures_towns.js';
  import DataService from './services/DataService.js';
  import {firebaseAuth} from '@/firestore';
  import { getClientId} from "./utils/cookie_utils.js";
  import { mapMutations } from 'vuex';
  export default {
    metaInfo: {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: 'lokali',
    },
    data () {
      return {
      lokali_bar: false,
      lg_account: null,
      show1 : false,
      lg_username : "",
      lg_password : "",
      login_error:null,
      re_username : "",
      re_displayname:"",
      re_password : "",
      re_confirmPwd:"",
      re_phone:"",
      re_prefecture:"",
      re_town:"",
      snackbar:false,
      snackbar_text:"",
      timeout:2000,
      rules: {
          required: value => !!value || 'Required.',
          min: v => v.length >= 8 || 'Min 8 characters',
          emailMatch: () => (`The email and password you entered don't match`),
        },
      prefectures:dat_prefectures,
      towns:[],
      prefecture:null,
      town: null,
      dialog: false,
      dialog2: false,
      value:1,
      bt_navigation:1,
      require_acc_dialog:false,
      debug: true,
    }},
    mounted(){
      console.log('[mounted]');
      firebaseAuth.onAuthStateChanged(() => {
        this.reset_account();
      });
      this.get_current_navigation();
      this.initClientId(getClientId(this.$cookie));
    },
    /*created() {
      window.addEventListener('scroll', this.onScroll);
    },
    destroyed() {
      window.removeEventListener('scroll', this.onScroll);
    },*/
    /*beforeDestroy(){
      alert('BEFORE DESTROY !!!');
      console.log('BEFORE DESTROY !!!');
    },*/
    methods:{
      ...mapMutations([
        'initClientId',
      ]),
      get_current_navigation(){
        console.log('[get_current_navigation], this.$route.name: ', this.$route.name);
        switch(this.$route.name){
          case 'Home': this.bt_navigation = 0;break;
          case 'Shops': this.bt_navigation = 0;break;
          case 'Shop': this.bt_navigation = 0;break;
          case 'Chats': this.bt_navigation = 1;break;
          case 'Chat': this.bt_navigation = 1;break;
          default: this.bt_navigation = 0;break;
        }
      },
      reset_account() {
        //console.log('[reset_account], firebaseAuth.currentUser: ', firebaseAuth.currentUser);
        if (firebaseAuth.currentUser)
          this.lg_account = firebaseAuth.currentUser;
        else
          this.lg_account = null;
      },
      async login(username, password){
        const result = await this.firebase_login(username, password);
        if (result) {
          this.lg_password = '';
          this.dialog = false;
        }else{
          this.login_error = "Login failed.";
        }
      },
      async firebase_login(username, password) {
        console.log('login: ', username, password);
        const lg_acc = await DataService.login(username, password)
          .then((response) => {
            var lg_data = response.data;
            console.log('login: ', lg_data);
            return lg_data;
          })
          .catch((e) => {
            console.log(e);
            return null;
          });
        //if login sucessfully
        if (lg_acc) {
          console.log('login sucessfully');
          const acc = await firebaseAuth.signInWithCustomToken(lg_acc.token)
            .then(function () {
              return true;

            }).catch((e) => {
              console.log(e);
              return false;
            });

          if (acc) {
            var user = firebaseAuth.currentUser;
            await user.updateProfile({
              displayName: lg_acc.displayname
            }).then(function () {
              console.log('[SIGN IN] udpate display name successfully !', lg_acc.displayname);
              return true;
            }).catch(function (error) {
              console.log('[SIGN IN] udpate display name failed', error);
              return false;
            });
          }
          return true;
        }else{
          return false;
        }
        //this.lg_account = firebaseAuth.currentUser;
        
        //console.log('[login], lg_account:', this.lg_account);
      },
      async logout(){
        await firebaseAuth.signOut();
        this.reset_account();
      },
      async register(){
        const result = await DataService.register(this.re_username, this.re_password, this.re_confirmPwd, this.re_displayname, this.re_phone, this.re_prefecture, this.re_town)
        .then(data => {
            console.log('[register], data: ', data);
            return data;
        }).catch((e) => {
            console.log('[register], error: ', e);
            return null;
        });
        if(result){
          console.log('result.status: ', result.status);
          if([200,201, 202,203].includes(result.status)){
            this.dialog2 = false;
            return this.login(this.re_username, this.re_password);
          }else{
            console.log(result.data);
          }
        }
      },
      prefectureCheck(){
        this.towns = dat_towns[this.re_prefecture];
        if(this.towns)
          return true;
        else
          return false;
      },
      common(){
        console.log('[common] call common method.');
        this.require_acc_dialog = true;
      },
      /*onScroll(e) {
        console.log('current scroll target', e.target);
      },*/
    }
  }
</script>
<!--<style lang="scss">-->

<style>
  @import url('https://fonts.googleapis.com/css2?family=Bungee+Inline&display=swap');
#app {
  font-family: Tahoma, none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.fixedContainer {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  margin-left: -16px !important;
  bottom: 0px;
  z-index:999;
}
.fixedContainer .question{
  font-size: 12px;
  
}
.fixedContainer .answer{
  font-size: 12px;
}
.fixedContainer .content{
  font-size: 14px !important;
  max-width: inherit !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.menuContainer {
        display: flex;
        position: fixed;
        right: 0px;
        top: 48px;
        z-index:999;
    }
.menuContainer .menuButton{
  z-index:999;
}
.menu-title{
  font-size: 14px !important;
}
.menu-item{
  font-size: 14px !important;
}
.fixedContainer_20210128 {
        display: flex;
        position: fixed;
        padding: 2em;
        right: 8px;
        bottom: 32px;
        z-index:999;
    }
    .expansion_panel_title {
      font-size: 18px;
      font-weight: 500;
      color: #ECEFF1 !important;
    }
    .basil--text {
    color: #424242 !important;
  }
  .basil {
    background-color: #FFFBE6 !important;
  }
    .v-application{
      font-family: Tahoma, none;
    }
    .fab_util_list{
      color : "rgba(55,71,79, 0.8)";
      max-height: 500px;
      overflow-y: scroll;
      border-radius: 4px;
    }
  .fab_util_list span{
    max-width: inherit;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: normal;
    font-size:14px;
  }
  .fab_util_list .v-sheet span{
    max-width: inherit;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: normal;
    font: size 14px;
  }
  .shop_beautiful_banner_old{
    font-family: Tahoma, none;
    font-size:32px;
    color: #fff;
    text-align: center;
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #039BE5;
  }
  .shop_beautiful_banner{
    font-family: Tahoma, none;
    font-size:32px;
    font-weight: 500;
    /**color: #fff;**/
    text-align: center;
    /**text-shadow: 0 0 1px #fff, 0 0 2px rgb(243, 243, 219)**/
  }
  @media (max-width: 640px) {
        .shop_beautiful_banner{
          font-size:24px;
        }
        .error_screen{
          width: 90%;
        }
    }
  .neon_text{
    text-shadow: 0 0 1px #fff, 0 0 3px #fff
  }
  .lokali_title{
    font-size:18px;
    font-weight: bold;
  }
  .headerClass2{
        line-height: 1.5em;
        height: 3em;
        font-size:14px;
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: normal;
    }
    .suggestionClass{
        font-size:12px;
        font-weight: 410;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: normal;
        color: #5D4037 !important;
    }
    .priceClass{
      width: fit-content;
      /**position:absolute;
      top:0px;
      left:0px;**/
    }
    .priceClass_old{
        line-height: 1.5em;
        height: 3em;
        white-space: nowrap ;
        word-break: normal;
        overflow: hidden ;
        text-overflow: ellipsis;
        font-size:12px;
        color: #E65100 !important;
    }
    .product_dialog{
        z-index: 10 !important;
    }
    .shop-intro{
      font-size: 16px;
    }
    .filter_component .filter_title{
      font-size: 12px;
    }
    .prod_detail {
        font-size:14px;
    }
    .prod_detail img{
        display: block;
        margin-left: auto;
        margin-right: auto;
        max-width: 75%;
    }
    .panel-div {
        font-size:14px;
    }
    .panel-div img{
        display: block;
        margin-left: auto;
        margin-right: auto;
        max-width: 75%;
    }
    .panel-div video{
      width: 600px;
      max-width: 90%;
      display: block;
      margin: 0 auto;
    }
    .overflow-hidden{
      white-space: nowrap !important;
      overflow: hidden !important;
      text-overflow: ellipsis !important;
      max-width: 90%;
    }
    .qw-title{
      font-size:16px;
      font-weight: 600;
    }
    .error_screen{
      position: fixed;
      top: 50%;
      left: 50%;
      /* bring your own prefixes */
      transform: translate(-50%, -50%);
    }
    .quickview_layout{
    font-size:14px;
  }
  /**@media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px){**/
  @media(max-width: 640px){
    .quickview_layout table, thead, tbody, th, td, tr{
      display: block !important;
    }
    .quickview_layout table tbody tr td:nth-child(1){
      font-size:14px;
      font-weight: 300 !important;
      font-style: italic !important;
    }
    .quickview_layout table tbody tr td:nth-child(1) p{
      margin-bottom: 0px;
    }
    .quickview_layout table tbody tr td:nth-child(2){
      padding-left: 16px;
      /**font-weight: 500 !important;**/
    }
  }
</style>
