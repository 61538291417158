function getClientId(cookie_obj) {
    //cookie data format
    //{"lokali":{"client_id":"", "location":{}}
    var cookie_data = null;
    var create_cookie = false;
    try {
        cookie_data = JSON.parse(cookie_obj.get("lokali_cookie"));        
        if (cookie_data != null && cookie_data["lokali"] != null) {
            return cookie_data["lokali"]["client_id"];
        } else {
            create_cookie = true;
        }
    } catch{
        create_cookie = true;
    }

    if (create_cookie){
        const currentDate = new Date();
        const timestamp = currentDate.getTime().toString();
        cookie_data = { "lokali": { "client_id": timestamp, "location": null } };
        cookie_obj.set("lokali_cookie", JSON.stringify(cookie_data), { expires: '6M' });
        return timestamp;
    }
}
function getClientLocation(cookie_obj){ 
    var cookie_data = null;
    try {
        cookie_data = JSON.parse(cookie_obj.get("lokali_cookie"));
        if (cookie_data != null && cookie_data["lokali"] != null) {
            return cookie_data["lokali"]["location"];
        } else {
            return null;
        }
    } catch{
        return null;
    }
}
function setClientLocation(cookie_obj, locationJson){
    try{
        var create_cookie = false;
        var cookie_data = null;
        var l_cookie = cookie_obj.get("lokali_cookie");
        if (l_cookie != null){
            cookie_data = JSON.parse(l_cookie);
            if (cookie_data != null && cookie_data["lokali"] != null && cookie_data["lokali"]["client_id"] != null){
                cookie_data["lokali"]["location"] = locationJson;
                cookie_obj.set("lokali_cookie", JSON.stringify(cookie_data), { expires: '6M' });
                return 1;
            }else{
                create_cookie = true;
            }
        }else{
            create_cookie = true;
        }
        if (create_cookie){
            const currentDate = new Date();
            const timestamp = currentDate.getTime().toString();
            cookie_data = {"lokali":{"client_id": timestamp, "location": locationJson}};
            cookie_obj.set("lokali_cookie", JSON.stringify(cookie_data), { expires: '6M' });
            return 1;
        }
    }catch{
        return null;        
    }
}


export {getClientId, getClientLocation, setClientLocation};
