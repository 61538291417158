import Vue from 'vue'
import vuetify from './plugins/vuetify';
//import Vuex from 'vuex';
import router from './router'
import App from './App.vue'

// import plugin
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
// don't forget to import CSS styles
import 'tiptap-vuetify/dist/main.css'
// Vuetify's CSS styles
import 'vuetify/dist/vuetify.min.css'

import { store } from './store/store.js';

//20210626 use vue-cookies
var VueCookie = require('vue-cookie');

Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: 'mdi', // VuetifyIconsGroups (fa, md, mdi, mdiSvg)
})

//use cookies
Vue.use(VueCookie);

Vue.config.productionTip = false

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
