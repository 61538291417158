export const dat_prefectures = ['Cần Thơ','Đà Nẵng','Hải Phòng','Hà Nội','TP HCM',
      'An Giang','Bà Rịa - Vũng Tàu','Bắc Giang','Bắc Kạn','Bạc Liêu', 'Bắc Ninh','Bến Tre','Bình Định','Bình Dương','Bình Phước','Bình Thuận','Cà Mau','Cao Bằng','Đắk Lắk','Đắk Nông','Điện Biên','Đồng Nai','Đồng Tháp','Gia Lai','Hà Giang',
      'Hà Nam','Hà Tĩnh','Hải Dương','Hậu Giang','Hòa Bình','Hưng Yên','Khánh Hòa','Kiên Giang','Kon Tum','Lai Châu','Lâm Đồng','Lạng Sơn','Lào Cai','Long An','Nam Định','Nghệ An','Ninh Bình','Ninh Thuận','Phú Thọ','Quảng Bình',
      'Quảng Nam','Quảng Ngãi','Quảng Ninh','Quảng Trị','Sóc Trăng','Sơn La','Tây Ninh','Thái Bình','Thái Nguyên','Thanh Hóa','Thừa Thiên Huế','Tiền Giang','Trà Vinh','Tuyên Quang','Vĩnh Long','Vĩnh Phúc','Yên Bái','Phú Yên'];
export const dat_towns = {'Cần Thơ':[],
         'Đồng Nai':['Biên Hòa', 'Long Khánh', 'Tân Phú', 'Vĩnh Cửu', 'Định Quán', 'Trảng Bom', 'Thống Nhất', 'Cẩm Mỹ', 'Long Thành', 'Xuân Lộc', 'Nhơn Trạch'],
         'TP HCM':['Quận 1','Quận 12','Quận Thủ Đức','Quận 9','Quận Gò Vấp','Quận Bình Thạnh','Quận Tân Bình','Quận Tân Phú','Quận Phú Nhuận','Quận 2','Quận 3','Quận 10','Quận 11','Quận 4','Quận 5','Quận 6','Quận 8','Quận Bình Tân','Quận 7','Huyện Củ Chi','Huyện Hóc Môn','Huyện Bình Chánh','Huyện Nhà Bè','Huyện Cần Giờ']
        }

//export const loc_provinces = ['H\u1ed3 Ch\u00ed Minh','\u0110\u1ed3ng Nai'];
//export const loc_towns = {'H\u1ed3 Ch\u00ed Minh':{'Qu\u1eadn Ba':{"lat": "10.7749", "lng": "106.6863"},'Qu\u1eadn M\u1ed9t':{"lat": "10.7807", "lng": "106.6994"},'Th\u1ee7 \u0110\u1ee9c':{"lat": "10.8486", "lng": "106.7721"}},
//'\u0110\u1ed3ng Nai':{'Long Kh\u00e1nh':{"lat": "10.9265", "lng": "107.2478"}}};
export const loc_provinces = ['H\u1ed3 Ch\u00ed Minh'];
export const loc_towns = {'H\u1ed3 Ch\u00ed Minh':{'Qu\u1eadn M\u1ed9t':{"lat": "10.7807", "lng": "106.6994"},'Th\u1ee7 \u0110\u1ee9c':{"lat": "10.8486", "lng": "106.7721"}}};
