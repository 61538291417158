import DataService from '../services/DataService.js';

async function getSuggestions(shopId, navHistory){
    var sourceIds = navHistory.slice(Math.max(navHistory.length - 3, 0));
    var suggestion_list = [];
    await Promise.all(sourceIds.map(async (sourceId) => {
        const asuggestion = await DataService.getSuggestions(shopId, sourceId);
        asuggestion.result.list.array.forEach(element => {
            if (sourceIds.indexOf(element) < 0 ){
                suggestion_list.push(element);
            }
        });
      }));
    return suggestion_list;
}

async function addSuggestions(shopId, navHistory){
    //console.log('[addSuggestions] shopId, navHistory:',shopId, navHistory);
    var sourceId = navHistory;
    var suggestion_list = [];
    const asuggestion = await DataService.getSuggestions(shopId, sourceId);
    //console.log('[addSuggestions] asuggestion:',asuggestion);
    if(asuggestion && asuggestion.data.result){
        suggestion_list = asuggestion.data.result.list;
    }
    //console.log('[addSuggestions] suggestion_list:', suggestion_list);
    return suggestion_list;
}

export {getSuggestions, addSuggestions};
