import app from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/database'
import 'firebase/storage'
import 'firebase/messaging'

/*const config =
	process.env.NODE_ENV === 'development'
		? JSON.parse(process.env.VUE_APP_FIREBASE_CONFIG)
		: JSON.parse(process.env.VUE_APP_FIREBASE_CONFIG_PUBLIC)*/
/*const config = {
	apiKey: "AIzaSyBRn5YzL8TcpD7xknW1VyeVLLDDd5y8bJY",
	authDomain: "doccms-488e0.firebaseapp.com",
	databaseURL: "https://doccms-488e0.firebaseio.com",
	projectId: "doccms-488e0",
	storageBucket: "doccms-488e0.appspot.com"
};*/

var config_ = null;
if (process.env.NODE_ENV == "development") {
//lokal-it
config_ = {
	apiKey: "AIzaSyCad6mXP04YX-nj1XtDagptBh286a97c5s",
	authDomain: "lokal-it-dev.firebaseapp.com",
	databaseURL: "https://lokal-it-dev.firebaseio.com",
	projectId: "lokal-it-dev",
	storageBucket: "lokal-it-dev.appspot.com",
	messagingSenderId: "599137577515",
    appId: "1:599137577515:web:4634452d19eba2f92d186e",
    measurementId: "G-7T9ET9WWD0"
};}else if (process.env.NODE_ENV == "production"){
//lokali-product
config_ = {
	apiKey: "AIzaSyB4VqzKAQpuis5jpFEoi0gJLWXh_xsPP5w",
	authDomain: "lokali-product.firebaseapp.com",
	projectId: "lokali-product",
	storageBucket: "lokali-product.appspot.com",
	messagingSenderId: "948709244115",
	appId: "1:948709244115:web:dcba6c75e5bb509b096826",
	measurementId: "G-NE04S5M74S"
  };
}

const config = config_;

app.initializeApp(config)

export const firebase = app
export const firebaseAuth = app.auth()
export const db = app.firestore()
export const storageRef = app.storage().ref()

//TODO: chat feature
//export const usersRef = db.collection('ChatUsers')
//export const roomsRef = db.collection('ChatRooms')
export const usersRef = null
export const roomsRef = null

export const filesRef = storageRef.child('files')

export const dbTimestamp = firebase.firestore.FieldValue.serverTimestamp()
export const deleteDbField = firebase.firestore.FieldValue.delete()
