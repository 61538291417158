import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors'
Vue.use(Vuetify);

export default new Vuetify({
    customVariables: ['~/scss/variables.scss'],
    theme: {
        light: true,
        themes: {
            light: {
                primary: colors.teal.darken4,
                secondary: colors.grey.darken1,
                accent: colors.shades.black,
                error: colors.red.accent3,
              },
              dark: {
                primary: colors.blue.lighten3,
                secondary: colors.grey.darken1,
                accent: colors.shades.black,
                error: colors.red.accent3,
              },
        },
      },
});
