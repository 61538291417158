import Vue from 'vue'
import Vuex from 'vuex';

import {addSuggestions} from '../utils/suggestion_utils.js'
import DataService from '../services/DataService.js'

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    storedHistory: [],
    suggestions: [],
    suggestionsTopN: [],
    shopId: null,
    clientId: null,
    historyId: null,
    colorSet: null,
  },
  getters: {
    storedHistory: state => state.storedHistory,
    storedHistoryMatches(state) {
      return result => {
        return state.storedHistory === result;
      }
    },
    suggestions: state => state.suggestions,
    shopId: state => state.shopId,
    clientId: state => state.clientId,
    suggestionsTopN: state => state.suggestionsTopN,
    colorSet: state => state.colorSet,
  },
  mutations: {
    incrementStoredNumber(state) {
      state.safelyStoredNumber++;
    },
    addStoredHistory(state, viewAction) {
      state.storedHistory.push(viewAction);
      this.updateSuggestion_(state, { 'nav': viewAction, 'accept': true });

      //save history to db
      let ts = Date.now();
      let newHistoryItem = ts.toString() + "#" + viewAction;
      if (!state.historyId) {
        DataService.addHistory(state.shopId, { clientId: state.clientId, list: [newHistoryItem] })
          .then((result) => {
            state.historyId = result.data.result;
            console.log('state.historyId', state.historyId);
          }).catch((error) => {
            console.log(error);
          })
      } else {
        console.log('update history', state.historyId, newHistoryItem);
        DataService.updateHistory(state.shopId, state.historyId, { list: [newHistoryItem] });
      }
    },
    updateSuggestion(state, suggestionAction) {
      this.updateSuggestion_(state, suggestionAction);
      /*if (!suggestionAction.accept) {
        var match_index = state.suggestions.findIndex(item => { return (item.destination == suggestionAction.nav); });
        if (match_index != -1) {
          state.suggestions[match_index].score -= 1;
        }
      }
      addSuggestions(state.shopId, suggestionAction.nav).then(async(result) => {
        /*await Promise.all(result.map(element => {
          element.score *= 2;
          if (!suggestionAction.accept){
            element.score = -element.score;
          }
          var match_index = state.suggestions.findIndex(item => {return (item.destination == element.destination);});
          //if not exist, add to suggestion list
          if (match_index == -1){
            state.suggestions.push(element);
          }else{ //if exist, update score
            state.suggestions[match_index].score += element.score;
          }
        }));

        await Promise.all(state.suggestions.map(element => {
          element.score /= 2;
          var match_index = state.storedHistory.findIndex(item => {return (item == element.destination);});
          //console.log('match_index:', element.destination, match_index);
          if (match_index > -1){
            element.score /= 2;
          }
        }));
        state.suggestions.sort(function(a, b){return a.score - b.score});
        state.suggestions.reverse();
        state.suggestionsTopN = state.suggestions.slice(0, 3);
        //console.log('state.suggestions:', state.suggestions);
        //state.suggestionsTopN = state.suggestions.slice(Math.max(state.suggestions.length - 10, 0));
      }).catch((e) => {
        console.log(e);
      });*/
    },
    createShopId(state, shopInfo) {
      state.shopId = shopInfo.shopId;
      if (shopInfo.theme) {
        state.colorSet = shopInfo.theme;
        if (!shopInfo.theme.buttonColor) {
          state.colorSet.buttonColor = shopInfo.theme.background;
        }
        if (!shopInfo.theme.overallColor) {
          state.colorSet.overallColor = "rgb(246, 249, 251)";
        }
        if (!shopInfo.theme.textColor) {
          state.colorSet.textColor = "blue-grey--text text--lighten-5";
        }
      }
      else {
        state.colorSet = { overallColor: "rgb(246, 249, 251)", background: "rgb(56,95,115)", backgroundTrans: "rgba(56,95,115,0.5)", foreColor: "white--text text--lighten-5", textColor: "blue-grey--text text--lighten-5", buttonColor: "rgb(56,95,115)" };
      }
    },
    initClientId(state, clientId) {
      state.clientId = clientId;
    },
  }
});

  store.updateSuggestion_ = (state, suggestionAction) =>{
    //console.log('suggestionAction:', suggestionAction);
    if (!suggestionAction['accept']) {
      var match_index = state.suggestions.findIndex(item => { return (item.destination == suggestionAction['nav']); });
      if (match_index != -1) {
        state.suggestions[match_index].score -= 1;
      }
    }
    addSuggestions(state.shopId, suggestionAction['nav'])
    .then(async(result) => {
      await Promise.all(result.map(element => {
        element.score *= 2;
        if (!suggestionAction['accept']){
          element.score = -element.score;
        }
        var match_index = state.suggestions.findIndex(item => {return (item.destination == element.destination);});
        //if not exist, add to suggestion list
        if (match_index == -1){
          state.suggestions.push(element);
        }else{ //if exist, update score
          state.suggestions[match_index].score += element.score;
        }
      }));

      await Promise.all(state.suggestions.map(element => {
        element.score /= 2;
        var match_index = state.storedHistory.findIndex(item => {return (item == element.destination);});
        //console.log('match_index:', element.destination, match_index);
        if (match_index > -1){
          element.score /= 2;
        }
      }));
      state.suggestions.sort(function(a, b){return a.score - b.score});
      state.suggestions.reverse();
      state.suggestionsTopN = state.suggestions.slice(0, 3);
      //console.log('state.suggestions:', state.suggestionsTopN);
      //state.suggestionsTopN = state.suggestions.slice(Math.max(state.suggestions.length - 10, 0));
    }).catch((e) => {
      console.log(e);
    });
  }